.top-section{
  height: 360px;
  background: url("../img/decor/top-bg.jpg") no-repeat;
  background-size: cover;
  padding: 20px 10px;
  @include phone-hor-orientation{
    height: 100vh;
    background-position: center center;
  }
}
.top-section__title{
  position: absolute;
  top: 20px;
  right: 50px;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  font-family: 'furore',sans-serif;
  & span{
    font-size: 35px;
    font-weight: 400;
    @include phone-hor-orientation{
      font-size: 25px;
    }
  }
  @include phone-hor-orientation{
    font-size: 18px;
    @include centered(both);
    width: 70%;
  }
}
.top-contacts{
  position: absolute;
  display: flex;
  bottom: 27px;
  right: 250px;
  @include phone-hor-orientation{
    flex-direction: column;
    padding-left: 20px;
    @include centered(x);
    width: 100%;
    bottom: 100px;
    justify-content: center;
  }
}
.top-contacts__item{
  color: #fff;
  font-size: 16px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  @include phone-hor-orientation{
    margin-bottom: 10px;
  }
}
.top-contacts__icon{
  margin-right: 5px;
}
.top-cotacts__text{

}
.callBtn{
  position: absolute;
  bottom: 20px;
  right: 50px;
  @include phone-hor-orientation{
    @include centered(x);
    width: 45%;
    bottom: 5px;
  }
}
.priceBtn{
  position: absolute;
  bottom: 70px;
  right: 60px;
  @include phone-hor-orientation{
    @include centered(x);
    width: 40%;
    bottom: 60px;
  }
}