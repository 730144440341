.call-form__bg{
  transition: 1s;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 10000;
  background-color: rgba(#b5b5b5, .7);
}

.call-form__wrapper{
  position: relative;
  padding: 25px;
  border: 2px solid $red;
  background: #F0EFEF;
  border-radius: 5px;
}
.cancelBtn{
  display: flex;
  counter-reset:black;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  &:hover{
    color: $red;
    cursor: pointer;
  }
}
.call-form__title{
  text-align: center;
  margin-bottom: 20px;
  color: $red;
}
.call-form{
  width: 300px;
}
.input-group{
  text-align: center;
}
.input,.input_long{
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  &:focus{
    border: 1px solid $red;
  }
}
.input{
  margin-bottom: 20px;
  margin-right: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}
