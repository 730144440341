.complects__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.complects__item{
  margin: 20px;
  width: 25%;
  @include tablet{
    width: 40%;
  }
  @include phone-hor-orientation{
    width: 70%;
  }
}
.complect__item-name{
  color: black;
  font-size: 15px;
  margin-bottom: 15px;
  text-align: center;
}
.complect__item-img{

}
.complects__item-disc{
  font-size: 17px;
  padding-top: 50px;
  @include phone-hor-orientation{
    padding-top: 0;
  }
}