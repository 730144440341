.galery-section{
  height: 100%;
}
.gallery{
  text-align: center;
}
.object__link{
  position: relative;
  width: 200px;
  height: 200px;
  margin: 10px;
  display: inline-block;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  border-radius: 15px;
  overflow: hidden;
  @include phone-hor-orientation{
    width: 255px;
  }
}
.object__img {
  @include centered(both);
  display: block;
  width: 100%;
  height: auto;
}