
@mixin centered($asix: 'both') {
  position: absolute;

  @if ($asix == 'both') {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @if ($asix == 'x') {
    left: 50%;
    transform: translateX(-50%);
  }

  @if ($asix == 'y') {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin desktop {
  @media (max-width: 1280px) {
    @content;
  }
}
@mixin tabletPro {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin ipadPro {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin laptop {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin phone-hor-orientation {
  @media (max-width: 550px) {
    @content;
  }
}
@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}


