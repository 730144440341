.sub-title{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}
.center{
  text-align: center;
}
.text{
  margin-bottom: 20px;
}
.styled-list{
  list-style: disc;
  padding-left: 20px;
  text-transform: uppercase;
}
.float-right{
  float: right;
  margin: 5px;
  @include phone-hor-orientation{
    display: block;
    width: 100%;
    float: none;
  }
}
.float-left{
  float: left;
  margin: 5px;
  @include phone-hor-orientation{
    display: block;
    width: 100%;
    float: none;
  }
}

.clearfix{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}
.text__title{
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 400;
}
.samorez-img{
  @include phone-hor-orientation{
    display: block;
    width: 100%;
    &.small{
      width: 60%;
      margin: 0 auto;
    }
  }
}
  //###########################| LOGO |##########################################
.logo{
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 20px;
  left: 50px;
  &_footer{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 200px;
    top: 20px;
    right: 20px;
    @include phone-hor-orientation{
      display: none;
    }
  }
  @include phone-hor-orientation{
    flex-direction: column;
    @include centered(x);
  }
}
.logo__img{
  width: 350px;
  margin-right: 10px;
  &_footer{
    margin-right: 0;
    margin-bottom: 5px;
  }
  @include tablet{
    width: 200px;
  }
  @include phone-hor-orientation{
    margin-bottom: 10px;
    width: 300px;
  }
}
.logo__text{
  color: #fff;
  font-size: 16px;
}
//###########################| MAIN-CONTENT |##########################################
.main-content{
  background-color: #616161;
  display: flex;
  padding: 0 5px 5px 5px;
  &__left-col{
    width: 75%;
    margin-right: 5px;
    border-radius: 5px;
    background-color: #f0efef;
    @include tablet{
      width: 100%;
      margin-right: 0;
    }
  }
  &__right-col{
    width: 25%;
    text-align: center;
    @include tablet{
      display: none;
    }
  }
}
//###########################| BUTTON |##########################################
.button{
  display: inline-block;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  background-color: $red;
  &:hover{
    cursor: pointer;
    background-color: #eb4545;
  }
  &_download{
    font-size: 12px;
    font-weight: 400;
    padding: 10px 35px;
  }
  @include phone-hor-orientation{
    text-align: center;
  }
}
.calculateBtn{
  margin-bottom: 20px;
}
//###########################| SECTION |##########################################
.section{
  background-color: #f0efef;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  @include phone-hor-orientation{
    padding: 10px;
  }
}
.section-title{
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 700;
}
//###########################| SHEME SECTION |##########################################
.sheme-section{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
  @include phone-hor-orientation{
    flex-direction: column;
    align-items: center;
  }
}
.sheme-section__left-col{
  width: 33%;
  padding: 0 15px;
  text-align: center;
  @include phone-hor-orientation{
    width: 90%;
    margin-bottom: 10px;
  }
}
.sheme-section__title{
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'furore',sans-serif;
  font-size: 16px;
  @include tablet{
    font-size: 16px;
  }
  & span{
    font-size: 14px;
    font-weight: normal;
  }
}
.more-link{
  color: grey;
  &:hover{
    text-decoration: underline;
  }
}
.sheme__disc{
  font-size: 13px;
  & span{
    border-top: 1px solid black;
  }
}
.sheme-section__midl-col{
  width: 33%;
  padding: 0 15px;
  @include phone-hor-orientation{
    width: 90%;
    margin-bottom: 10px;
  }
}
.sheme__img{
  width: 100%;
  display: block;
}
.sheme-section__right-col{
  width: 33%;
  padding: 0 15px;
  @include phone-hor-orientation{
    width: 90%;
  }
}
.sheme-section__picture{
  width: 100%;
  display: block;
}

.backBtn{
  color: $red;
  position: absolute;
  bottom: 20px;
  left: 20px;
  &:hover{
    font-weight: bold;
  }
}