.nav{
  padding: 5px 10px 0 10px;
  background-color: #616161;
  @include tablet{
    padding: 10px 15px;
  }
}
.nav__list{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include tablet{
    justify-content: center;
  }
}
.nav__item{
  margin-right: 20px;
  margin-bottom: 5px;
}
.nav__link{
  color: #fff;
  border: 2px solid #fff;
  padding: 5px 10px;
  border-radius: 5px;
  display: block;
  &:hover{
    background-color: #fff;
    color: $red;
  }
  @include phone-hor-orientation{
    padding: 5px;
  }
}