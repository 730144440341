
.advantages__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include phone-hor-orientation{
    flex-direction: column;
    align-items: center;
  }
}
.advantages__item{
  width: 20%;
  @include tablet{
    width: 30%;
    margin-bottom: 20px;
  }
  @include phone-hor-orientation{
    width: 80%;
  }
}
.advantages__name{
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
  line-height: 16px;
}
.advantages__icon{
  display: block;
  margin: 0 auto;
  width: 60%;
  @include phone-hor-orientation{
    width: 30%;
  }
}