.advertising{
  text-align: center;
  margin-bottom: 15px;
  display: block;
}
.advertising__title{
  color:#fff;
  text-align: center;
  margin-bottom: 5px;
}
.advertising__img{
  display: block;
  width: 100%;
  height: auto;
}