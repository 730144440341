.aside-nav__list{
  margin-bottom: 15px;
}
.aside-nav__item{
  background-color: #f0efef;
  margin-bottom: 3px;
  padding: 5px;
  &:first-child{
    border-radius: 5px 5px 0 0;
  }
  &:last-child{
    border-radius: 0 0 5px 5px;
  }
}
.aside-nav__link{
  display: flex;
  font-size: 13px;
  font-weight: bold;
  align-items: center;
  &:hover{
    & .aside-nav__text{
      color: $red;
    }
  }
}
.aside-nav__icon{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
  color: #fff;
}
.aside-nav__text{
  color: black;
}