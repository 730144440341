.aksessyars__list{
  display: flex;
  flex-wrap: wrap;
}
.aksessyars__item{
  background-color: #fdfdfd;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  width: 29.5%;
  margin: 15px;
  &:hover{
    & .aksessyars__overlay{
      display: block;
    }
  }
  @include tablet{
    width: 40%;
  }
  @include phone-hor-orientation{
    width: 60%;
  }
  @include phone{
    width: 80%;
  }
}
.aksessyars__link{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.aksessyars__overlay{
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .3);
}
.aksessyars__icon{
  @include centered(both);
  color: #fff;
  font-size: 20px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aksessyars__name{
  text-align: center;
  font-size: 16px;
  background-color: #363636;
  text-transform: uppercase;
  padding: 10px;
  color: #fff;
}
.aksessyars__img{
  display: block;
  width: 100%;
}