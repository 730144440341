.aside-info__list{
  text-align: center;
  margin-bottom: 20px;
}
.aside-info__item{
  margin-bottom: 15px;
}
.aside-info__title{
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
.aside-info__img{
  display: block;
  width: 100%;
}