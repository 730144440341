.catalog{

}
.catalog__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.catalog__item{
  position: relative;
  width: 48%;
  display: flex;
  background-color: #fff;
  margin: 0 5px 20px 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  &:hover{
    box-shadow: 0 0 15px rgba(0,0,0,0.5);
    cursor: pointer;
    & .product__name:before{
      top: -15px;
    }
    & .product__name:after{
      bottom: -15px;
    }
  }
  @include tablet{
    width: 100%;
  }
}
.catalog__item-link{
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.catalog-item__left-col{
  width: 50%;
}
.product__img{
  display: block;
  width: 100%;
  height: 100%;
}
.product__disc{
  width: 50%;
  padding: 30px 5px;
  position: relative;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product__name{
  font-size: 17px;
  text-align: center;
  position: relative;
  &:before{
    display: block;
    width: 60%;
    height: 2px;
    background-color: $red;
    position: absolute;
    top: -10px;
    @include centered(x);
    content: ' ';
    transition: .3s;
  }
  &:after{
    display: block;
    width: 60%;
    height: 2px;
    background-color: $red;
    position: absolute;
    bottom: -10px;
    @include centered(x);
    content: ' ';
    transition: .3s;
  }
  @include ipadPro{
    font-size: 15px;
  }
  @include phone-hor-orientation{
    font-size: 14px;
  }
}
.product__text{
  font-size: 14px;
  line-height: 20px;
  width: 80%;
  margin: 0 auto;
}
.product__moreBtn{
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red;
  &:hover{
    cursor: pointer;
    background-color: #eb2222;
  }
}
.moreBtn__icon{
  color: #fff;
}