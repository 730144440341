
.partners__list{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include tablet{
    flex-direction: column;
  }
}
.partners__item{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 25px;
  margin-bottom: 5px;
  @include tablet{
    margin-bottom: 10px;
  }
}
.partners__img{
  width: 100px;
}