.metallocherepitca-section{
  height: 100%;
}
.products__top-img{
  width: 100%;
  display: block;
  margin-bottom: 40px;
}
.products__text{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
}