.sofit-img__list{
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.sofit-img__item{
  flex-basis: 191px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.sofit-img__item-pic{
  display: block;
  width: 100%;
}
.sofit-img__item-name{
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}