.shtaket__list{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @include tablet{
    flex-direction: column;
  }
}
.shtaket__item{
  width: 40%;
  margin: 20px;
  @include phone-hor-orientation{
    width: 80%;
  }
}
.shtaket__name{
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
}
.shtaket__pic{
  display: block;
  width: 100%;
}
.shtaketnik-slider__img{
  width: 100%;
  display: block;
}
.shtaketnik-slider{
  margin-bottom: 20px;
  position: relative;
}