.calculator-section{
  height: 100%;
}
.calculator__top-img{
  display: block;
  width: 100%;
  margin-bottom: 40px;
}
.calculator__text{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 50px;
  position: relative;
  padding-bottom: 60px;
}
.calculator-section__donloadBtn{
  bottom: 0;
  @include centered(x);
}
#result_calc_page{
  padding: 20px;
  border-radius: 5px;
  width: 50%;
  text-align: center;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  margin: 0 auto;
  @include tablet{
    width: 100%;
  }
}
.sortable{
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  &:last-child{
    margin-bottom: 0;
  }
}
select, input{
  height: 45px;
  outline: none;
  padding: 5px;
  border-radius: 5px;
  &:focus{
    border: 1px solid $red;
  }
}
#view_result{
  padding-bottom: 20px;
  margin: 0 auto;
  color: $red;
  font-weight: bold;
}
#calc-result{
  margin-bottom: 20px;
}