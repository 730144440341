.greeting-section__content{
  display: flex;
  @include tablet{
    flex-direction: column;
  }
}
.greeting-section__left-col{
  width: 60%;
  position: relative;
  padding-bottom: 35px;
  @include tablet{
    width: 100%;
    margin-bottom: 20px;
  }
}
.greeting-section__text{
  padding-left: 50px;
  font-weight: 400;
  & ul{
    padding-left: 20px;
    list-style: disc;
  }
  @include tablet{
    padding-left: 0;
  }
}
.greeting-section__right-col{
  width: 40%;
  @include tablet{
    width: 100%;
  }
}
.greeting-section__img{
  display: block;
  width: 100%;
}
.downloadBtn{
  position: absolute;
  right: 20px;
  bottom: 20px;
  @include tabletPro{
    bottom: -5px;
    right: 20px;
  }
}