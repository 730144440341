.contacts-section{
  height: 100%;
}
.contacts-section__content{
  display: flex;
  flex-direction: column;
}
.contacts-section__left-col{
  width: 100%;
  margin-bottom: 20px;
}
.contaccts__item{
  margin-bottom: 20px;
  & span{
    font-weight: bold;
  }
}
.contaccts__sub-item{
  margin-bottom: 5px;
  & span{
    font-weight: bold;
  }
}
.contacts-section__right-col{
  width: 100%;
}
