footer{
  background-color: #f0efef;
}
.footer{
  height: 200px;
  background-color: #771010;
  position: relative;
  padding: 20px 80px;
  display: flex;
  @include phone-hor-orientation{
    padding: 20px;
  }
}
.footer-products__list, .footer-instructions__list {
  display: inline-block;
  padding-right: 50px;
  @include phone-hor-orientation{
    padding-right: 20px;
  }
}
.footer-instructions__list{
  @include phone-hor-orientation{
    padding-right: 0;
  }
}
.footer-producrs__title, .footer-instructions__title{
  margin-bottom: 20px;
  font-weight: 400;
  color: #fff;
  @include phone-hor-orientation{
    margin-bottom: 10px;
  }
}
.footer-producrs__item, .footer-instructions__item{
  color: #fff;
}
.footer-producrs__link, .footer-instructions__link{
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  &:hover{
    cursor: pointer;
    color: #848282;
  }
}