//#############################| TOP BLOCK |#####################################
.product-item-card{
  background-color: #c3c3c3;
  padding: 15px;
  position: relative;
  border-radius: 5px 5px 0 0;
}
.product-item-card__title{
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 30px;
  font-weight: 700;
}
.product-item-card__content{
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  @include tablet{
    flex-direction: column;
    align-items: center;
  }
}
.product-item-card__left-col{
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  @include tablet{
    margin-bottom: 10px;
    width: 90%;
  }
}
.product-card__logo{
  position: absolute;
  width: 150px;
  top: 10px;
  left: 0;
  @include tablet{
    display: none;
  }
}
.product-item-card__img{
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.product-item-card__right-col{
  width: 45%;
  padding-left: 20px;
  @include tablet{
    width: 90%;
    padding-left: 0;
  }
  @include phone-hor-orientation{
    width: 98%;
  }
}
.product-item-card__table{
  width: 100%;
}
.product-item-card__table-title{
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
.product-item-card__table__cell{
  padding: 10px;
  font-weight: bold;
  font-size: 17px;
  border: 1px solid #000;

  &.light-cell{
    background-color: #d9e4f1;
  }
  &.dark-cell{
    background-color: #7eb4e6;
  }
}
//#############################| SECOND BLOCK |#####################################
.product-series-block{
  padding: 15px;
  background-color: #f0efef;
  border-radius: 0 0 5px 5px;
}
.product-series-block__text{
  text-align: center;
  font-size: 14px;
  width: 70%;
  margin: 0 auto 20px auto;
  @include tablet{
    width: 85%;
  }
}
.product-series-item{
  width: 85%;
  margin: 0 auto 30px auto;
  position: relative;
  padding-bottom: 20px;
  &:after{
    display: block;
    content: ' ';
    width: 70%;
    height: 2px;
    background-color: #626262;
    position: absolute;
    @include centered(x);
    bottom: 0;
  }
  @include tablet{
    width: 90%;
  }
}
.product-series-item__title{
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}
.product-series-item__content{
  display: flex;
  flex-wrap: wrap;
  @include laptop{
    flex-direction: column;
    align-items: center;
  }
}
.product-series-item__left-col{
  width: 28%;
  & img{
    display: block;
    width: 100%;
  }
  @include laptop{
    width: 70%;
    margin-bottom: 20px;
  }
}
.product-series-item__right-col{
  width: 72%;
  padding-left: 40px;
  position: relative;
  @include laptop{
    width: 97%;
    padding-left: 0;
  }
}
.garantie-card{
  position: absolute;
  top: -20px;
  right: 40px;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../img/decor/darantie-decor.png") no-repeat;
  background-position: center center;
  background-size: 100%;
  @include phone-hor-orientation{
    position: static;
    margin: 0 auto;
  }
}
.garantie-card__text{
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-weight: 100;
  & span{
    font-weight: bold;
  }
}
.product-series-item__info{
  font-size: 16px;
  & span{
    font-weight: bold;
    font-size: 17px;
  }
}
pre{
  margin: 0;
  font-size: 17px;
}


.delta-banner{
  display: block;
  width: 100%;
}