.prohdnue-elem__img{
  display: block;
  width: 100%;
}
.images-block{
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;
  @include laptop{
    flex-direction: column;
    align-items: center;
  }
  &:last-child{
    margin-right: 0;
  }
  & img{
    margin-right: 10px;
    @include laptop{
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
}
