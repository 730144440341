.about-section{
  height: 100%;
}
.about-section__text{
  margin-bottom: 20px;
}
.about-section__picture{
  margin-top: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.about-section__img{
  @include tablet{
    display: block;
    width: 85%;
    height: auto;
  }
}
ol{
  padding-left: 50px;
}