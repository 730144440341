.instructions-section{
  height: 100%;
  text-align: center;
}
.instruction-gallery{

}
.instruction__link{
  position: relative;
  width: 200px;
  height: 300px;
  margin: 10px;
  display: inline-block;
  overflow: hidden;
  @include phone-hor-orientation{
    width: 255px;
  }
}
.instruction__img{
  @include centered(both);
  display: block;
  width: 100%;
  height: auto;
}