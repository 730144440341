.zaglushki__list{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @include laptop{
    justify-content: center;
  }
}
.zaglushki__item{
  flex-basis: 282px;
}
.zaglushki__item-img{
  display: block;
  width: 100%;
}